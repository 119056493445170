import * as React from "react"
import Button from "./button"
import { motion, useViewportScroll } from "framer-motion"
import classNames from "classnames"

const mouseVariant = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 },
}

const Hero = ({
  heroText,
  heroDesc,
  heroButtonLinkPrimary,
  heroButtonTextPrimary,
  heroButtonLinkSecondary,
  heroButtonTextSecondary,
  isVisible,
  extraClasses
}) => {

  const { scrollY } = useViewportScroll();
  const [mouseHidden, setMouseHidden] = React.useState(false);

  const updateMouse = () => {
    if (scrollY?.current > 150) {
      setMouseHidden(true);
    } else {
      setMouseHidden(false);
    }
  }

  React.useEffect(() => {
    return scrollY.onChange(() => updateMouse());
  }, [scrollY]);

  const handleClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className={classNames("block block-hero", extraClasses)}>
      <div className="block-content g-m-1-13 g-l-4-12 g-4-12">
        {heroText && (
          <h2 className="block-header">
            {heroText}
          </h2>
        )}
        {heroDesc && (
          <p className="block-post t-36">
            {heroDesc}
          </p>
        )}
        {mouseHidden !== null && (
          <motion.div className="hide-mq-tablet mouse"
            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
            animate={mouseHidden ? "hidden" : "visible"}
            variants={mouseVariant}>
            <a
              onClick={handleClick}
              style={{
                height: "100%",
                width: "100%",
                opacity: 0,
                position: "absolute",
              }}
            >
              LINK
            </a>
          </motion.div>
        )}
        {(heroButtonLinkPrimary || heroButtonLinkSecondary) && (
          <div className="button-group">
            {heroButtonLinkPrimary &&
              <Button buttonClass="primary" buttonLink={heroButtonLinkPrimary} buttonText={heroButtonTextPrimary} />
            }
            {heroButtonLinkSecondary &&
              <Button buttonClass="secondary" buttonLink={heroButtonLinkSecondary} buttonText={heroButtonTextSecondary} />
            }
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
